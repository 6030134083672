<template>
  <dialog class="lui-package-cookieconsent c-dialog c-dialog-cookieconsent">
    <div class="c-dialog-content">
      <div class="c_head mt-2">
        <div class="ui-heading md:mb-8">Informace o cookies</div>
      </div>
      <div class="c_body">
        <div class="ui-text">
          <p>Webové stránky používají k poskytování služeb, personalizaci reklam a analýze návštěvnosti soubory
            cookies.</p>
          <p>Následující volbou souhlasíte s využíváním cookies a použití údajů o Vašem chování na webu pro zobrazení
            cílené reklamy.</p>
          <p>Personalizaci a cílenou reklamu si můžete kdykoliv vypnout nebo upravit. Nyní udělený souhlas je platný po
            dobu 1 roku.</p>
        </div>
      </div>
      <div class="c_foot gap-4 flex-col px-4">
        <div class="flex flex-col w-full">
          <button class="ui-btn lg w-full" data-lib-cookieconsent-approve>Ano, souhlasím</button>
        </div>
        <div class="flex w-full gap-4">
          <a class="ui-btn ghosted lg accent-main flex-1 px-4"
             href="https://www.rentea.cz/gdpr/"><span>Přizpůsobit</span></a>
          <button class="ui-btn ghosted lg accent-main flex-1 px-4" data-lib-cookieconsent-decline>
            <span>Nesouhlasím</span></button>
        </div>
      </div>
    </div>
  </dialog>
</template>

<script setup>
import {onMounted} from "vue";

onMounted(() => {
  window.luiCookieConsentInit();
});
</script>


<style scoped>

</style>
